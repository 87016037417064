import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../../components/PageWrapper";
import { Select } from "../../components/Core";
import { DataContext } from "../../context/DataContext";
import { AuthContext } from "../../context/AuthContext";
import { validation } from "../../utils/validation";
import { login } from "../../utils/utilities";
import notify from "../../utils/notify";
import { hireTalent } from "../../api";

const HireTalent = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [myJobs, setMyJobs] = useState(null);
  const [hours, setHours] = useState(1);
  const { auth } = useContext(AuthContext);
  const {
    talent,
    getTalentDetails,
    commissionRate,
    talentRate,
    getjobsList,
  } = useContext(DataContext);
  const { register, errors, handleSubmit, reset, control } = useForm();
  const param = params[`*`];

  const fetchTalentDetails = async (talentId) => {
    if (talentId) {
      await getTalentDetails(talentId);
      await getHiringDetails();
    } else {
      navigate(-1);
    }
    const clientId = auth?.client?.account_id || auth?.admin?.account_id;
    const data = await getjobsList(clientId, "client");
    // Transform jobs array
    const parsedJobs = data
      .filter(({ status }) => status === "pending")
      .map(({ id, position }) => ({
        label: position,
        value: id,
      }));
    setMyJobs(parsedJobs);
    setLoading(false);
  };

  const getHiringDetails = async () => {
    const payload = localStorage.getItem("hiringPayload");
    if (payload) {
      // console.log(payload);
      const response = await hireTalent(JSON.parse(payload));
      // console.log("response ", response);
      notify("Proposal sent successfully", "success");
      setTimeout(
        () =>
          notify(
            `We'll notify you when ${talent.firstname} ${talent.lastname} has accepted it.`,
            "success"
          ),
        1000
      );
      localStorage.removeItem("hiringPayload");
      clearTimeout();
      navigate(`/candidate-profile/${talent.account_id}`, {
        state: { id: talent.account_id },
      });
    }
  };

  useEffect(() => {
    const talentId = param;
    fetchTalentDetails(talentId);
    return () => {
      setLoading(true);
      clearTimeout();
    };
  }, []);

  const onSubmit = async (data) => {
    try {
      if (auth && auth?.talent) {
        notify(
          "Only clients can hire talents. Create a Client account to continue"
        );
      } else if (!auth) {
        notify("Login to hire this talent");
      } else {
        setSubmitting(true);
        const numberOfHours = parseFloat(data.hours);
        const commission = parseFloat(
          (hours * talent?.rate * (commissionRate / 100)).toFixed(2)
        );
        const talentFee = parseFloat(hours * talent?.rate);
        const total = parseFloat(
          hours * talent?.rate +
            parseFloat(
              (hours * talent?.rate * (commissionRate / 100) + 1).toFixed(2)
            )
        );
        const payload = {
          talent: talent.account_id,
          client: auth?.client.account_id,
          hours: numberOfHours,
          devshopFee: commission,
          talentFee: talentFee,
          total: Math.floor(total),
          currency: talent.currency,
          jobId: data.job.value,
          jobTitle: data.job.label,
        };
        // console.log(payload);
        localStorage.setItem("hiringPayload", JSON.stringify(payload));
        reset();
        const contractId = await hireTalent(payload);
        setSubmitting(false);
        notify("Proposal sent successfully", "success");
        setTimeout(
          () =>
            notify(
              `We'll notify you when ${talent.firstname} ${talent.lastname} has accepted it.`,
              "success"
            ),
          1000
        );
        localStorage.removeItem("hiringPayload");
        setTimeout(
          () =>
            navigate(`/contract-details/${contractId}`, {
              state: { id: contractId },
            }),
          500
        );
      }
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row">
              <div className="col-3 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="d-flex align-items-center ml-4"
                  >
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-11">
              <div className="col-12 col-xxl-7 col-lg-7 col-md-7 mb-11">
                <div className="mb-11">
                  {loading ? (
                    <Skeleton
                      height={44}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <h3>Hire {`${talent?.firstname} ${talent?.lastname}`}</h3>
                  )}
                </div>

                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="job"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Select Job*
                      </label>
                      <Controller
                        name="job"
                        control={control}
                        defaultValue=""
                        rules={validation.job}
                        as={
                          <Select
                            options={myJobs}
                            className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
                            border={true}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.job?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="hours"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Enter the number of hours*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="hours"
                        name="hours"
                        placeholder="Number of hours"
                        min={1}
                        step={0.1}
                        ref={register(validation.hours)}
                        onChange={(e) => setHours(e.target.value)}
                      />
                      <span className="text-danger small">
                        {errors?.hours?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={40}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <div className="mb-4 d-flex justify-content-between align-items-end">
                      <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <h3 className="font-size-5 text-gray mr-4">Cost</h3>
                        <h3 className="font-size-4 font-weight-normal text-gray">
                          (Number of hours * Talent Hourly Rate)
                        </h3>
                      </div>
                      <p className="font-size-5">
                        {hours}
                        {" * "}
                        {talent?.rate}
                        {" = "}
                        {(hours * talent?.rate)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        {talent?.currency}
                      </p>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={40}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <div className="mb-4 d-flex justify-content-between">
                      <h3 className="font-size-5 text-gray">Talent Fee</h3>
                      <p className="font-size-5">
                        {parseFloat(hours * talent?.rate).toFixed(2)}{" "}
                        {talent?.currency}
                      </p>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={40}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <div className="mb-4 d-flex justify-content-between align-items-end">
                      <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <h3 className="font-size-5 text-gray mr-4">
                          Devshop Commission
                        </h3>
                        <h3 className="font-size-4 font-weight-normal text-gray">{`(${commissionRate}%)`}</h3>
                      </div>
                      <div>
                        <p className="font-size-5">
                          {(
                            hours *
                            talent?.rate *
                            (commissionRate / 100)
                          ).toFixed(2)}{" "}
                          {talent?.currency}
                        </p>
                      </div>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={40}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <div className="mb-4 d-flex justify-content-between align-items-start">
                      <div className="d-flex align-items-center">
                        <h3 className="font-size-5 text-gray mr-4">
                          Contract Storage Fee
                        </h3>
                        <h3 className="font-size-5 text-gray font-weight-normal">
                          <i
                            className="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="Once the contract is completed and approved, the storage cost will be credited back to your wallet."
                          ></i>
                        </h3>
                      </div>
                      <p className="font-size-5">{`1 ${talent?.currency}`}</p>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={40}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <div className="mb-8 d-flex justify-content-between">
                      <h3 className="font-size-5 text-gray">Total Cost</h3>
                      <p className="font-size-5">
                        {parseFloat(hours * talent?.rate) +
                          parseFloat(
                            (
                              hours * talent?.rate * (commissionRate / 100) +
                              1
                            ).toFixed(2)
                          )}{" "}
                        {talent?.currency}
                      </p>
                    </div>
                  )}

                  <div className="form-group mb-4">
                    {loading ? (
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                        disabled={submitting || loading ? true : false}
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </button>
                    )}
                  </div>
                  <div className="mb-8">
                    <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">
                      By clicking the pay button, you confirm that understand
                      and agree to the DevShop Terms of Service, including the
                      User Agreement and Privacy Policy
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default HireTalent;
